<div fxLayout="row">
  <mat-tab-group
    #tabs
    animationDuration="0ms"
    (selectedTabChange)="onTabSelected()"
  >
    <mat-tab [label]="'Installed Applications' | translate">
      <ng-template matTabContent>
        <ix-charts (updateTab)="updateTab($event)"></ix-charts>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Available Applications' | translate">
      <ng-template matTabContent>
        <ix-catalog></ix-catalog>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Manage Catalogs' | translate">
      <ng-template matTabContent>
        <ix-manage-catalogs></ix-manage-catalogs>
      </ng-template>
    </mat-tab>
    <mat-tab [label]="'Manage Docker Images' | translate">
      <ng-template matTabContent>
        <ix-docker-images-list></ix-docker-images-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
