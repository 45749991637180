<ng-container matColumnDef="select">
  <th *matHeaderCellDef mat-header-cell [style.width.px]="40">
    <mat-checkbox
      color="primary"
      ixTest="column-select-all"
      [checked]="isChecked$ | async"
      [indeterminate]="isIndeterminate$ | async"
      [aria-label]="checkboxLabel()"
      (change)="onChange($event)"
    ></mat-checkbox>
  </th>
  <td *matCellDef="let item; dataSource: getDataSource()" mat-cell [style.width.px]="40">
    <mat-checkbox
      color="primary"
      ixTest="column-select"
      [checked]="selection.isSelected(item)"
      [aria-label]="checkboxLabel(item)"
      (click)="$event.stopPropagation()"
      (change)="$event ? selection.toggle(item) : null"
    ></mat-checkbox>
  </td>
</ng-container>
