<button
  name="Power"
  mat-icon-button
  class="topbar-button-right"
  ixTest="power-menu"
  [matTooltip]="tooltips.power | translate"
  [matMenuTriggerFor]="powerMenu"
>
  <ix-icon name="power_settings_new"></ix-icon>
</button>
<mat-menu #powerMenu="matMenu">
  <button name="power-log-out" mat-menu-item ixTest="log-out" (click)="onSignOut()">
    <ix-icon name="exit_to_app"></ix-icon>
    {{ 'Log Out' | translate }}
  </button>

  <button name="power-restart" mat-menu-item ixTest="restart" (click)="onReboot()">
    <ix-icon name="replay"></ix-icon>
    {{ 'Restart' | translate }}
  </button>

  <button name="power-shut-down" mat-menu-item ixTest="shut-down" (click)="onShutdown()">
    <ix-icon name="power_settings_new"></ix-icon>
    {{ 'Shut Down' | translate }}
  </button>
</mat-menu>
