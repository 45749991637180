
<button
  mat-button
  type="button"
  class="action-button"
  ixTest="login-to-jira"
  [disabled]="hasValue()"
  [color]="value ? 'accent' : 'primary'"
  (click)="initSession()"
>
  {{ hasValue() ? ('Logged In To Jira' | translate) : ('Login to Jira' | translate) }}
</button>

<div class="full-width container has-tooltip hidden">
  <div *ngIf="label" class="label-container">
    <label class="label">
      {{ label }}
      <span *ngIf="required" class="required">*</span>
    </label>
    <ix-tooltip
      *ngIf="tooltip"
      class="tooltip"
      [header]="label"
      [message]="tooltip"
    ></ix-tooltip>
  </div>

  <div
    class="outlined input-container"
    [class.has-reset-button]="shouldShowResetInput()"
  >
    <input
      matInput
      ixTest="token"
      [value]="value"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      (blur)="onTouch()"
    />
    <span *ngIf="shouldShowResetInput()" class="reset-input">
      <ix-icon
        name="mdi-close-circle"
        [matTooltip]="'Reset the token' | translate"
        (click)="resetInput()"
      ></ix-icon>
    </span>
  </div>
  <ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</div>
