<h1 matDialogTitle>{{ 'Delete' | translate }}</h1>
<form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
  <p *ngIf="!isJobCompleted">
    <strong>
      {{ 'The following { n, plural, one {docker image} other {# docker images} } will be deleted. Are you sure you want to proceed?' | translate: { n: bulkItems.size } }}
    </strong>
  </p>
  <p *ngIf="isJobCompleted">
    <strong *ngIf="failedCount">
      {{ 'Warning: {n} of {total} docker images could not be deleted.' | translate: { n: failedCount, total: bulkItems.size } }}
    </strong>
    <strong *ngIf="successCount">
      {{ '{n, plural, one {# docker image} other {# docker images}} has been deleted.' | translate: { n: successCount } }}
    </strong>
  </p>

  <div class="bulk-list">
    <ix-bulk-list-item
      *ngFor="let bulkItem of bulkItems | keyvalue; trackBy: trackByKey"
      [item]="bulkItem.value"
    >
      <span>
        {{ bulkItem.value.item.repo_tags.length ? bulkItem.value.item.repo_tags.join(', ') : bulkItem.value.item.id }}
      </span>
    </ix-bulk-list-item>
  </div>

  <div class="form-actions">
    <div class="form-actions-cell">
      <ix-checkbox
        *ngIf="!isJobCompleted"
        formControlName="force"
        [label]="'Force delete' | translate"
      ></ix-checkbox>

      <ix-checkbox
        *ngIf="!isJobCompleted"
        formControlName="confirm"
        [label]="'Confirm' | translate"
        [required]="true"
      ></ix-checkbox>
    </div>

    <div class="form-actions-cell">
      <button
        *ngIf="!isJobCompleted"
        mat-button
        type="submit"
        color="primary"
        ixTest="delete"
        [disabled]="form.invalid"
      >
        {{ 'Delete' | translate }}
      </button>

      <button
        *ngIf="!isJobCompleted"
        mat-button
        type="button"
        matDialogClose
        ixTest="cancel"
      >
        {{ 'Cancel' | translate }}
      </button>
      <button
        *ngIf="isJobCompleted"
        mat-button
        type="button"
        ixTest="close"
        [matDialogClose]="true"
      >
        {{ 'Close' | translate }}
      </button>
    </div>
  </div>
</form>
