<ng-template ixPageHeader>
  <ix-page-title-header>
    <ix-search-input (search)="onSearch($event)"></ix-search-input>

    <button mat-button ixTest="refresh-all" (click)="onRefreshAll()">
      {{ 'Refresh All' | translate }}
    </button>

    <button mat-button ixTest="add-catalog" (click)="doAdd()">
      {{ 'Add Catalog' | translate }}
    </button>

    <ix-common-apps-toolbar-buttons></ix-common-apps-toolbar-buttons>
  </ix-page-title-header>
</ng-template>

<ix-entity-table [title]="title" [conf]="this"></ix-entity-table>
