<ix-label
  *ngIf="label || tooltip"
  [label]="label"
  [tooltip]="tooltip"
  [required]="required"
  [ixTestOverride]="controlDirective.name"
></ix-label>

<div class="input-container" [class.disabled]="isDisabled">
  <input
    #input
    matInput
    type="text"
    autocomplete="off"
    [value]="inputValue"
    [ixTest]="controlDirective.name"
    [required]="required"
    [disabled]="isDisabled"
    (change)="onInputChanged(input.value)"
    (blur)="onTouch()"
  />
</div>
<div class="tree-container" [class.disabled]="isDisabled">
  <tree-root
    #tree
    [nodes]="nodes"
    [options]="treeOptions"
    (select)="onNodeSelect($event)"
    (deselect)="onNodeDeselect($event)"
  >
    <!-- Do not remove #treeNodeTemplate even though it appears to be unused -->
    <ng-template #treeNodeTemplate let-node>
      <span *ngIf="typeNode(node) as node" [class.selected]="isPathSelected(node.data.path)">
        <ng-container *ngIf="node.data.type === ExplorerNodeType.File; else folderIcon">
          <ix-icon name="insert_drive_file"></ix-icon>
        </ng-container>

        <ng-template #folderIcon>
          <ix-icon name="folder"></ix-icon>
        </ng-template>

        <span class="node-name">{{ node.data.name }}</span>
      </span>
    </ng-template>
  </tree-root>
</div>

<mat-error *ngIf="loadingError" class="loading-error">
  {{ loadingError }}
</mat-error>

<ix-errors [control]="controlDirective.control" [label]="label"></ix-errors>

<mat-hint *ngIf="hint">{{ hint }}</mat-hint>
