<div class="toolbar-menu">
  <div>
    <!-- TRIGGER -->
    <button
      mat-button
      color="default"
      class="menu-toggle"
      [ixTest]="['toolbar-menu', label]"
      [matMenuTriggerFor]="menu"
    >
      {{ label }} <ix-icon name="mdi-menu-down" class="menu-caret"></ix-icon>
    </button>
    <mat-menu #menu="matMenu" multiple overlapTrigger="false">
      <!-- SELECT ALL -->
      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          [ixTest]="['toolbar-menu', label, 'select-all']"
          (click)="checkAll()"
        >
          <span>
            <ix-icon *ngIf="checkLength()" name="check_circle"></ix-icon>
            <ix-icon *ngIf="!checkLength()" name="remove"></ix-icon>
          </span>
          <span>Select All</span>
        </button>
      </div>

      <!-- OPTIONS-->
      <div (click)="$event.stopPropagation()">
        <button
          *ngFor="let option of options; let i = index"
          mat-menu-item
          [ixTest]="['toolbar-menu', label, option.label]"
          (click)="onClick(option, i)"
        >
          <span>
            <ix-icon *ngIf="checkLength()" name="check_circle"></ix-icon>
            <ix-icon *ngIf="!checkLength()" name="remove"></ix-icon>
          </span>
          <span>{{ option.label | translate }}</span>
          <span *ngIf="option.labelIcon" [class]="['icon-suffix', option.labelIconType]">
            &nbsp;
            <ix-icon
              *ngIf="!option.labelIconType || option.labelIconType === 'default'"
              [name]="option.labelIcon"
            ></ix-icon>
            <ix-icon
              *ngIf="option?.labelIconType === 'community'"
              [name]="'mdi-' + option.labelIcon"
            ></ix-icon>
            <ix-icon
              *ngIf="option?.labelIconType === 'custom'"
              [name]="option.labelIcon"
            ></ix-icon>
          </span>
        </button>
      </div>
    </mat-menu>
  </div>
</div>
