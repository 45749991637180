<mat-toolbar class="topbar">
  <mat-toolbar-row>
    <!-- Sidenav toggle button -->
    <button
      mat-icon-button
      id="sidenavToggle"
      matTooltipPosition="right"
      ixTest="toggle-sidenav"
      [matTooltip]="tooltips.toggle_collapse | translate"
      (click)="toggleCollapse()"
    >
      <ix-icon name="menu"></ix-icon>
    </button>

    <span fxFlex></span>

    <a class="mobile-logo" routerLink="/" ixTest="mobile-logo">
      <div class="mobile-logo-container">
        <ix-icon
          name="ix:logo_truenas_scale_mark_color"
          class="logomark"
        ></ix-icon>

        <ix-icon
          name="ix:logo_truenas_scale_type_color"
          class="logotype"
        ></ix-icon>
      </div>
    </a>

    <div class="topbar-mobile-footer">
      <div class="ix-systems-logo-icon">
        <ix-icon
          tabindex="0"
          [class]="['ix-logo', screenSize, getLogoIcon()]"
          [name]="getLogoIcon()"
          (click)="openIx()"
        ></ix-icon>
      </div>

      <ix-truecommand-button></ix-truecommand-button>

      <!-- finish update -->
      <button
        *ngIf="upgradeWaitingToFinish"
        mat-icon-button
        id="finish-update"
        class="topbar-button-right"
        ixTest="finish-update"
        [matTooltip]="tooltips.upgrade_waiting | translate"
        (click)="upgradePendingDialog()"
      >
        <ix-icon class="movement" name="update"></ix-icon>
      </button>
      <button
        *ngIf="updateIsRunning"
        mat-icon-button
        id="update-running"
        class="topbar-button-right"
        ixTest="update-is-running"
        [matTooltip]="tooltips.update | translate"
        (click)="showUpdateDialog()"
      >
        <ix-icon class="movement" name="system_update_alt"></ix-icon>
      </button>
      <button
        *ngIf="pendingNetworkChanges"
        mat-icon-button
        id="network"
        class="topbar-button-right"
        ixTest="has-pending-network-changes"
        [matTooltip]="tooltips.pending_network_changes | translate"
        (click)="showNetworkChangesPending()"
      >
        <ix-icon class="movement" name="device_hub"></ix-icon>
      </button>
      <button
        *ngIf="showResilvering"
        mat-icon-button
        id="resilver"
        class="topbar-button-right"
        ixTest="resilvering"
        [matTooltip]="tooltips.resilvering | translate"
        (click)="showResilveringDetails()"
      >
        <ix-icon class="fa-spin" name="autorenew"></ix-icon>
      </button>
      <!-- HA Status -->
      <span *ngIf="isFailoverLicensed && haStatusText">
        <button
          *ngIf="haDisabledReasons.length === 0"
          mat-icon-button
          id="ha"
          class="topbar-button-right"
          ixTest="ha-indicator-enabled"
          [matTooltip]="haStatusText | translate"
          (click)="showHaStatus()"
        >
          <ix-icon name="ix:ha_enabled"></ix-icon>
        </button>
        <button
          *ngIf="haDisabledReasons.length > 0"
          mat-icon-button
          id="ha"
          class="topbar-button-right"
          ixTest="ha-indicator-disabled"
          [matTooltip]="haStatusText | translate"
          (click)="showHaStatus()"
        >
          <ng-container *ngIf="haDisabledReasons[0] === FailoverDisabledReason.NoSystemReady; else haDisabledIcon">
            <ix-icon name="ix:ha_reconnecting"></ix-icon>
          </ng-container>

          <ng-template #haDisabledIcon>
            <ix-icon name="ix:ha_disabled"></ix-icon>
          </ng-template>
        </button>
      </span>

      <ix-directory-services-indicator></ix-directory-services-indicator>
      <ix-jobs-indicator></ix-jobs-indicator>

      <!-- Alert toggle button -->
      <button
        *appLet="alertBadgeCount$ | async as alertBadgeCount"
        matBadgeSize="small"
        matBadgeColor="warn"
        mat-icon-button
        class="topbar-button-right"
        ixTest="alerts-indicator"
        [matBadge]="alertBadgeCount"
        [matBadgeHidden]="alertBadgeCount === 0"
        [matTooltip]="tooltips.alerts | translate"
        (click)="onAlertIndicatorPressed()"
      >
        <ix-icon name="notifications"></ix-icon>
      </button>

      <ix-user-menu></ix-user-menu>
      <ix-power-menu></ix-power-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
