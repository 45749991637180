<ix-modal-header
  [title]="'File Ticket' | translate"
  [loading]="isFormLoading$ | async"
></ix-modal-header>

<div *ngFor="let job$ of jobs$ | async" class="ix-modal-subheader">
  <ix-job-item *ngIf="job$ | async as job" [job]="job"></ix-job-item>
</div>

<mat-card>
  <mat-card-content>
    <form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
      <ix-fieldset>
        <div *ngIf="!(isEnterprise$ | async)" class="link">
          <a href="https://www.truenas.com/community/" ixTest="['forum-link']">
            {{ 'TrueNAS Forums - Discuss with fellow TrueNAS community users' | translate }}
          </a>
        </div>
        <ix-jira-oauth
          formControlName="token"
          [label]="'Token' | translate"
          [tooltip]="tooltips.token | translate"
          [required]="true"
        ></ix-jira-oauth>

        <ix-select
          formControlName="category"
          [label]="'Category' | translate"
          [options]="categoryOptions$"
          [tooltip]="tooltips.category | translate"
          [required]="true"
        ></ix-select>

        <ix-select
          formControlName="type"
          [label]="'Type' | translate"
          [options]="typeOptions$"
          [tooltip]="tooltips.type | translate"
          [required]="true"
        ></ix-select>

        <ix-checkbox
          formControlName="attach_debug"
          [label]="'Attach Debug' | translate"
          [tooltip]="tooltips.attach_debug | translate"
        ></ix-checkbox>

        <ix-input
          formControlName="title"
          [label]="'Subject' | translate"
          [tooltip]="tooltips.title | translate"
          [placeholder]="tooltips.title | translate"
          [required]="true"
        ></ix-input>

        <ix-textarea
          formControlName="body"
          [label]="'Body' | translate"
          [tooltip]="tooltips.body | translate"
          [placeholder]="tooltips.body | translate"
          [rows]="8"
          [required]="true"
        ></ix-textarea>

        <ix-file-input
          formControlName="screenshot"
          [label]="'Attach screenshots' | translate"
          [tooltip]="tooltips.screenshot | translate"
          [multiple]="true"
          [acceptedFiles]="acceptedFiles"
        ></ix-file-input>
      </ix-fieldset>

      <ix-form-actions>
        <button
          mat-button
          type="submit"
          color="primary"
          ixTest="save"
          [disabled]="isFormDisabled$ | async"
        >
          {{ 'Save' | translate }}
        </button>
      </ix-form-actions>
    </form>
  </mat-card-content>
</mat-card>
