<ng-template ixPageHeader>
  <ix-page-title-header>
    <ix-search-input (search)="onSearch($event)"></ix-search-input>

    <button
      mat-button
      ixTest="bulk-actions-menu"
      [matMenuTriggerFor]="menu"
      [disabled]="filteredChartItems.length === 0"
    >
      {{ 'Bulk Actions' | translate }}
      <ix-icon name="mdi-menu-down" class="menu-caret"></ix-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        *ngIf="!areAllAppsSelected"
        mat-menu-item
        ixTest="select-all"
        (click)="$event.stopPropagation(); onSelectAll()"
      >
        <ix-icon name="mdi-check-circle-outline"></ix-icon>
        <span>{{ 'Select All' | translate }}</span>
      </button>
      <button
        *ngIf="areAllAppsSelected"
        mat-menu-item
        ixTest="unselect-all"
        (click)="$event.stopPropagation(); onUnselectAll()"
      >
        <ix-icon name="mdi-check-circle"></ix-icon>
        <span>{{ 'Unselect All' | translate }}</span>
      </button>
      <button
        mat-menu-item
        ixTest="start-selected"
        [disabled]="!isSomethingSelected"
        (click)="onBulkStart()"
      >
        <ix-icon name="mdi-play"></ix-icon>
        <span>{{ 'Start' | translate }}</span>
      </button>
      <button
        mat-menu-item
        ixTest="stop-selected"
        [disabled]="!isSomethingSelected"
        (click)="onBulkStop()"
      >
        <ix-icon name="mdi-stop"></ix-icon>
        <span>{{ 'Stop' | translate }}</span>
      </button>
      <button
        mat-menu-item
        ixTest="upgrade-selected"
        [disabled]="!hasUpdates"
        (click)="onBulkUpgrade()"
      >
        <ix-icon name="mdi-update"></ix-icon>
        <span>{{ 'Upgrade' | translate }}</span>
      </button>
      <button
        mat-menu-item
        ixTest="delete-selected"
        [disabled]="!isSomethingSelected"
        (click)="onBulkDelete()"
      >
        <ix-icon name="mdi-delete"></ix-icon>
        <span>{{ 'Delete' | translate }}</span>
      </button>
    </mat-menu>

    <ix-common-apps-toolbar-buttons></ix-common-apps-toolbar-buttons>
  </ix-page-title-header>
</ng-template>

<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card *ngFor="let item of filteredChartItems" class="chart-item">
    <div class="content-wrapper" fxLayout="row">
      <div class="logo-container">
        <div class="logo">
          <img
            [src]="item.chart_metadata.icon"
            [src-fallback]="imagePlaceholder"
          />
        </div>
        <div
          class="badge-area"
          [class.badge-official]="item.catalog === officialCatalog"
        >
          <div class="capitalized-text">
            {{ item.catalog }}
          </div>
          <div class="train-badge capitalized-text">
            {{ item.catalog_train }}
          </div>
        </div>
        <mat-checkbox
          color="primary"
          class="bulk-checkbox"
          [ixTest]="['select', item.name]"
          [(ngModel)]="item.selected"
        ></mat-checkbox>
      </div>
      <div class="content-box" (click)="showChartEvents(item.name)">
        <div class="content">
          <strong
            class="chart-name"
            textLimiter
            [threshold]="20"
            [content]="item.name"
          ></strong>
          <div>
            <span
              class="version"
              textLimiter
              [threshold]="50"
              [content]="item.human_version"
            ></span>
          </div>
          <div *ngIf="item.update_available || item.container_images_update_available; else elseBlock">
            <span
              class="update-label"
              [matTooltip]="'Available version: {version}' | translate: { version: item.human_latest_version }"
            >
              {{ 'Update available' | translate }}
            </span>
          </div>
          <ng-template #elseBlock>
            <div class="update-label">
              {{ 'Up to date' | translate }}
            </div>
          </ng-template>
        </div>

        <div
          class="chart-button-row"
          fxLayout="row"
          fxLayoutAlign="end end"
          (click)="$event.stopPropagation()"
        >
          <ng-container *ngIf="item.status === ChartReleaseStatus.Active">
            <div class="ports">
              <span
                class="ports-text"
                textLimiter
                [threshold]="28"
                [content]="appService.getPorts(item) | lowercase"
              >
              </span>
            </div>
            <div *ngFor="let portal of item.portals | keyvalue">
              <button
                mat-button
                [ixTest]="['portal', item.name, portal.key]"
                (click)="portalLink(item, portal.key)"
              >
                {{ portalName(portal.key) }}
              </button>
            </div>
          </ng-container>
          <button
            *ngIf="item.status !== ChartReleaseStatus.Stopped"
            mat-button
            [ixTest]="['stop', item.name]"
            (click)="stop(item.name)"
          >
            {{ 'Stop' | translate }}
          </button>
          <button
            *ngIf="item.status === ChartReleaseStatus.Stopped"
            mat-button
            [ixTest]="['start', item.name]"
            (click)="start(item.name)"
          >
            {{ 'Start' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="more-info">
      <span
        class="status"
        [ngClass]="item.status === ChartReleaseStatus.Active ? 'active' : 'other'"
      >
        {{ item.status }}
        <ng-container *ngIf="item.pod_status.desired > 1">
          {{ item.pod_status.available }}/{{ item.pod_status.desired }}
        </ng-container>
      </span>
      <button
        name="chart-actions"
        mat-icon-button
        [ixTest]="['actions', item.name]"
        [matMenuTriggerFor]="actionsMenu"
      >
        <ix-icon name="more_vert"></ix-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button
          name="update"
          mat-menu-item
          [ixTest]="['upgrade', item.name]"
          [disabled]="!item.update_available && !item.container_images_update_available"
          (click)="update(item.name)"
        >
          <ix-icon name="call_made"></ix-icon>
          <span>{{ 'Upgrade' | translate }}</span>
        </button>
        <button
          name="roll-back"
          mat-menu-item
          [ixTest]="['rollback', item.name]"
          [disabled]="!item.history || isEmpty(item.history)"
          (click)="onRollback(item.name)"
        >
          <ix-icon name="replay"></ix-icon>
          <span>{{ 'Roll Back' | translate }}</span>
        </button>
        <button
          name="edit"
          mat-menu-item
          [ixTest]="['edit', item.name]"
          (click)="edit(item.name)"
        >
          <ix-icon name="edit"></ix-icon>
          <span>{{ 'Edit' | translate }}</span>
        </button>
        <button
          name="shell"
          mat-menu-item
          [ixTest]="['shell', item.name]"
          (click)="openShell(item.name)"
        >
          <ix-icon
            name="mdi-console-line"
            class="shell-icon"
          ></ix-icon>
          <span>{{ 'Shell' | translate }}</span>
        </button>
        <button
          name="logs"
          mat-menu-item
          [ixTest]="['logs', item.name]"
          (click)="openLogs(item.name)"
        >
          <ix-icon name="description"></ix-icon>
          <span>{{ 'Logs' | translate }}</span>
        </button>
        <button
          name="delete"
          mat-menu-item
          [ixTest]="['delete', item.name]"
          (click)="delete(item.name)"
        >
          <ix-icon name="delete"></ix-icon>
          <span>{{ 'Delete' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-card>

  <ix-entity-empty
    *ngIf="filteredChartItems.length === 0"
    [conf]="emptyPageConf"
  ></ix-entity-empty>
</div>
