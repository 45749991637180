<div class="container">
  <div class="label-container">
    <ix-label
      *ngIf="label || tooltip"
      [label]="label"
      [tooltip]="tooltip"
      [required]="required"
    ></ix-label>

    <button
      *ngIf="canAdd"
      mat-button
      type="button"
      class="add-btn"
      [ixTest]="['add-item', label]"
      (click)="addItem()"
    >
      {{ 'Add' | translate }}
    </button>
  </div>

  <div class="input-container" [class.disabled]="isDisabled">
    <ng-content></ng-content>

    <span *ngIf="empty" class="empty">
      {{ 'No items have been added yet.' | translate }}
    </span>
  </div>
</div>
