<div
  class="ix-slidein-title-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <h3 class="ix-formtitle">{{ title | translate }}</h3>
  <ng-container *ngIf="!disableClose">
    <ix-icon name="cancel" id="ix-close-icon" (click)="close()"></ix-icon>
  </ng-container>
</div>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
