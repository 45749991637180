<ix-modal-header [title]="title" [loading]="isLoading"></ix-modal-header>
<mat-card>
  <mat-card-content>
    <form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
      <ix-dynamic-form
        [dynamicSection]="dynamicSection"
        [dynamicForm]="form"
        [isEditMode]="!isNew"
        (addListItem)="addItem($event)"
        (deleteListItem)="deleteItem($event)"
      ></ix-dynamic-form>

      <ix-form-actions>
        <button
          mat-button
          type="submit"
          color="primary"
          ixTest="save"
          [disabled]="form.invalid || isLoading"
        >
          {{ 'Save' | translate }}
        </button>
      </ix-form-actions>
    </form>
  </mat-card-content>
</mat-card>
