<h1 matDialogTitle>
  {{ 'Choose a pool for Apps' | translate }}
</h1>
<form class="ix-form-container" [formGroup]="form" (submit)="onSubmit()">
  <ix-select
    formControlName="pool"
    [label]="'Pool' | translate"
    [required]="true"
    [options]="pools$"
  ></ix-select>

  <ix-checkbox
    *ngIf="canMigrateApplications"
    formControlName="migrateApplications"
    [label]="'Migrate applications to the new pool' | translate"
  ></ix-checkbox>

  <ix-form-actions>
    <button mat-button type="button" ixTest="cancel" [matDialogClose]="false">
      {{ 'Cancel' | translate }}
    </button>

    <button mat-button type="submit" color="primary" ixTest="choose" [disabled]="form.invalid">
      {{ 'Choose' | translate }}
    </button>
  </ix-form-actions>
</form>
