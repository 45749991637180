<!-- START OF CONTROLS SECTION -->
<div
  *ngIf="entity && conf"
  fxFlex
  class="entity-table-controls"
  fxLayout="row wrap"
  fxLayoutAlignGap="16px"
  fxLayoutAlign="end center"
>
  <form class="form-element search-field" id="row-filter">
    <mat-form-field floatPlaceholder="never">
      <span matPrefix class="search-icon-wrapper">
        <ix-icon name="search"></ix-icon>
      </span>
      <input
        #filter
        matInput
        autocomplete="off"
        ixTest="table-filter"
        [placeholder]="'Filter {item}' | translate: { item: entity.title | translate }"
        [value]="filterValue"
      />
      <span
        class="reset-icon-wrapper"
        matSuffix
        [class.invisible]="!filterValue || filterValue.length === 0"
      >
        <ix-icon
          name="mdi-close-circle"
          (click)="resetFilter()"
        ></ix-icon>
      </span>
    </mat-form-field>
  </form>

  <div *ngIf="conf && conf.customActions">
    <span *ngFor="let custBtn of conf.customActions">
      <button
        *ngIf="!conf.isCustomActionVisible || conf.isCustomActionVisible(custBtn.id)"
        mat-button
        type="button"
        color="default"
        [ixTest]="[conf.title, custBtn.name]"
        [id]="'cust_button_' + custBtn.name"
        (click)="custBtn['function']()"
      >
        {{ custBtn.name | translate }}
      </button>
    </span>
  </div>

  <!-- START COLUMN FILTER -->
  <div *ngIf="entity.columnFilter && entity.allColumns && entity.allColumns.length > 0">
    <button
      mat-button
      color="default"
      class="menu-toggle"
      [ixTest]="[conf.title, 'columns-menu']"
      [matMenuTriggerFor]="menu"
    >
      {{ 'Columns' | translate }}
      <ix-icon name="mdi-menu-down" class="menu-caret"></ix-icon>
    </button>
    <mat-menu #menu="matMenu" multiple overlapTrigger="false">
      <!-- SELECT ALL -->
      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          id="check-all"
          [ixTest]="[conf.title, 'select-all-columns']"
          (click)="entity.checkAll()"
        >
          <span>
            <ix-icon *ngIf="entity.checkLength()" name="check_circle"></ix-icon>
            <ix-icon *ngIf="!entity.checkLength()" name="remove"></ix-icon>
          </span>
          <span *ngIf="!entity.checkLength()">
            {{ 'Select All' | translate }}
          </span>
          <span *ngIf="entity.checkLength()">
            {{ 'Unselect All' | translate }}
          </span>
        </button>
      </div>

      <!-- INDIVIDUAL COLUMNS-->
      <div (click)="$event.stopPropagation()">
        <button
          *ngFor="let col of entity.allColumns"
          mat-menu-item
          [ixTest]="[conf.title, 'select-column', col.name]"
          [id]="'menu_option-' + col.name"
          (click)="entity.toggle(col)"
        >
          <span>
            <ix-icon *ngIf="entity.isChecked(col)" name="check_circle"></ix-icon>
            <ix-icon *ngIf="!entity.isChecked(col)" name="remove"></ix-icon>
          </span>
          <span>{{ col.name | translate }}</span>
        </button>
      </div>

      <div (click)="$event.stopPropagation()">
        <button
          mat-menu-item
          id="reset_col_view"
          [ixTest]="[conf.title, 'reset-columns-to-defaults']"
          (click)="entity.resetColViewToDefaults()"
        >
          <span>
            <ix-icon name="undo"></ix-icon>
          </span>
          <span>{{ 'Reset to Defaults' | translate }}</span>
        </button>
      </div>
    </mat-menu>
  </div>
  <!-- END COLUMN FILTER -->

  <!-- OLD TEMPLATE START -->
  <div *ngIf="this.totalActions > 0" class="entity-add-actions-wrapper">
    <button
      *ngIf="this.totalActions > 1; else elseBlock"
      mat-button
      color="primary"
      class="menu-toggle"
      [ixTest]="[conf.title, 'actions']"
      [matTooltip]="menuTriggerMessage | translate"
      [matMenuTriggerFor]="menu"
    >
      {{ 'Actions' | translate }}
      <ix-icon name="mdi-menu-down" class="menu-caret"></ix-icon>
    </button>
    <mat-menu #menu="matMenu" overlapTrigger="false">
      <button
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        mat-menu-item
        id="add_action_button"
        [ixTest]="[conf.title, 'add']"
        (click)="this.entity.doAdd()"
      >
        <span>{{ 'Add' | translate }}</span>
      </button>
      <button
        *ngFor="let action of actions"
        mat-menu-item
        [ixTest]="[conf.title, 'action', action.label]"
        [id]="'add_action_button_' + action?.label"
        (click)="action.onClick()"
      >
        <span>{{ action.label | translate }}</span>
      </button>
    </mat-menu>

    <ng-template #elseBlock>
      <!--When there is no routeAdd -->
      <button
        *ngIf="this.entity.conf.routeAdd || this.entity.conf.doAdd"
        mat-button
        color="primary"
        id="add_action_button"
        [ixTest]="[conf.title, 'add']"
        [matTooltip]="entity.conf.routeAddTooltip | translate"
        [disabled]="entity.conf.addBtnDisabled"
        (click)="this.entity.doAdd()"
      >
        {{ 'Add' | translate }}
      </button>
      <button
        *ngIf="!(this.entity.conf.routeAdd || this.entity.conf.doAdd) && this.totalActions === 1"
        mat-button
        color="primary"
        [ixTest]="[conf.title, 'actions', actions[0].label]"
        [id]="'add_action_button_' + actions[0]?.label"
        [matTooltip]="actions[0].label | translate"
        (click)="this.actions[0].onClick()"
      >
        {{ this.actions[0].label | translate }}
      </button>
    </ng-template>
  </div>
  <!-- OLD TEMPLATE END -->

  <div *ngIf="conf.globalConfig" id="config">
    <button
      mat-icon-button
      [ixTest]="[conf.title, conf.globalConfig.id]"
      [id]="conf.globalConfig.id"
      [matTooltip]="conf.globalConfig.tooltip"
      (click)="conf.globalConfig.onClick()"
    >
      <ng-container *ngIf="conf.globalConfig.icon; else settingsIcon">
        <ix-icon [name]="conf.globalConfig.icon"></ix-icon>
      </ng-container>
      <ng-template #settingsIcon><ix-icon name="settings"></ix-icon></ng-template>
    </button>
  </div>

  <mat-spinner
    *ngIf="!entity.showDefaults && entity.showSpinner"
    #entityspinner
    id="entity-spinner"
    class="spinner"
    [diameter]="40"
  >
  </mat-spinner>

  <!-- END OF CONTROLS SECTION -->
</div>
