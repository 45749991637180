<mat-list role="list">
  <ng-container *ngFor="let subItem of subMenuItems">
    <mat-list-item
      *ngIf="!subItem.isVisible$ || (subItem.isVisible$ | async)"
      routerLinkActive="selected"
      class="sidebar-list-item slide-in-nav-item"
      role="listitem"
      (click)="toggleMenu.emit()"
    >
      <a class="slidein-nav-link" [ixTest]="subItem.name" [routerLink]="['/', menuName, subItem.state]">
        {{ subItem.name | translate }}
      </a>
    </mat-list-item>
  </ng-container>
</mat-list>
