<h1 mat-dialog-title>{{ hasPool ? title : 'No Pools Found' | translate }}</h1>

<form class="ix-form-container" [formGroup]="form" (submit)="onPodSelect()">
  <div *ngIf="hasPool" mat-dialog-content>
    <ix-select
      formControlName="pods"
      [label]="'Pods' | translate"
      [required]="true"
      [options]="pods$"
    ></ix-select>
    <ix-select
      formControlName="containers"
      [label]="'Containers' | translate"
      [required]="true"
      [options]="containers$"
    ></ix-select>
    <ix-input
      *ngIf="dialogType === podSelectDialogType.Shell"
      formControlName="command"
      [label]="'Commands' | translate"
    ></ix-input>
    <ix-input
      *ngIf="dialogType === podSelectDialogType.Logs"
      formControlName="tail_lines"
      type="number"
      [label]="'Tail Lines' | translate"
      [required]="true"
    ></ix-input>
  </div>

  <div *ngIf="!hasPool" mat-dialog-content>
    {{ 'At least one pool must be available to use apps' | translate }}
  </div>

  <div mat-dialog-actions align="end">
    <button
      *ngIf="hasPool"
      mat-button
      type="submit"
      color="primary"
      ixTest="choose-pool"
      [disabled]="form.invalid"
    >
      {{ 'Choose' | translate }}
    </button>
    <button
      mat-button
      type="button"
      color="secondary"
      ixTest="cancel"
      (click)="dialogRef.close()"
    >
      {{ 'Cancel' | translate }}
    </button>
  </div>
</form>
